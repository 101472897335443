import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'lib-back-online-dialog',
  templateUrl: './back-online-dialog.component.html',
  styleUrls: ['./back-online-dialog.component.scss']
})
export class BackOnlineDialogComponent {

  @Output() isManualClosed = new EventEmitter<boolean>();

  public constructor(private modalDialogRef: MatDialogRef<BackOnlineDialogComponent>) {
  }

  public onManualClose(): void {
    window.location.href = "/home/dashboard";
    this.modalDialogRef.close();
  }

  public ngOnInit(): void {

  }
}
