import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ReconnectingEventSource from 'reconnecting-eventsource';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Settings } from '../models/settings.class';
import { ICniDto } from './czam-api/dtos/cni-dto';
import { LocalStorageService } from './local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from './error.service';

export class ClientQrCodeData {
  public sesameId: number | null = null;
  public ciamId: string | null = null;
  public creationDate: Date | null = null;
  public isExpired: boolean = true;

  private constructor() {
  }

  public static Parse(value: string): ClientQrCodeData | null {


    let qrCodeData = new ClientQrCodeData();

    //value:  ciamId;sesameId;creationDate;xxx
    const data = value.split(';');

    if (data.length < 3) {
      return null;
    }

    qrCodeData.ciamId = data[0];
    qrCodeData.sesameId = parseInt(data[1]);
    qrCodeData.creationDate = new Date(parseInt(data[2]) * 1000);
    qrCodeData.isExpired = qrCodeData.checkExpiredQrCode(data[2]);

    if (isNaN(qrCodeData.sesameId)) {
      return null;
    }

    if (Object.prototype.toString.call(qrCodeData.creationDate) === "[object Date]" && isNaN(qrCodeData.creationDate.getTime())) {
      return null;
    }

    return qrCodeData;
  }

  private checkExpiredQrCode(qrCodeCreationDate: string): boolean {
    let currentTime = new Date().getTime();
    let qrCodeTime = new Date(parseInt(qrCodeCreationDate) * 1000).getTime();
    let diffInSeconds = Math.abs(currentTime - qrCodeTime) / 1000;
    let diffInMinutes = Math.floor(diffInSeconds / 60);
    return diffInMinutes >= 10;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeviceManagerService {
  evtSource: EventSource;
  subjectCard?: Subject<string>;
  subjectCni?: Subject<ICniDto>;
  public subjectQrCode?: Subject<ClientQrCodeData>;
  private _deviceManagerStatut: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public $isAlive: Observable<boolean> = this._deviceManagerStatut.asObservable();
  public deviceManagerInstallerUrl: string = '';
  private baseUrl: string = 'http://127.0.0.1:5000/api';
  isConnected: boolean = false;


  constructor(private http: HttpClient, public errorService: ErrorService, public translateService: TranslateService) {
    this.evtSource = new ReconnectingEventSource(`${this.baseUrl}/event`);
    this.evtSource.onerror = (evt) => {
      console.log('evtSource.onerror : ', evt);
      this.isConnected = false;
      this._deviceManagerStatut.next(false);
    }
    this.evtSource.addEventListener("connected", (event: any) => {
      this.isConnected = true;
      this._deviceManagerStatut.next(true);
    });
    this.evtSource.addEventListener('error', (e) => {
      this._deviceManagerStatut.next(false);
      this.isConnected = false;
      console.log("An error occurred while attempting to connect.");
    });
  }

  onVipCardRead(): Observable<string> {
    if (!this.subjectCard) {
      this.subjectCard = new Subject();
      this.evtSource.addEventListener("cvip", (event: any) => {
        console.log('deviceManager cvip: ', <string>event.data);
        this.subjectCard?.next(<string>event.data);
        this._deviceManagerStatut.next(true);
      });
    }
    return this.subjectCard.asObservable();
  }

  onCniCardRead(): Observable<ICniDto> {
    if (!this.subjectCni) {
      this.subjectCni = new Subject();
      this.evtSource.addEventListener("cni", (event: any) => {
        let cniData = <ICniDto>JSON.parse(event.data);
        console.log('deviceManager cni', cniData);

        // replaces one or more consecutive whitespace characters with a single space
        const normalizedFirstName = cniData.firstName.replace(/\s+/g, ' ').trim();
        cniData.firstName = normalizedFirstName;
        if (cniData.sex != null && cniData.sex.length > 0) {
          cniData.sex = cniData.sex[0].toUpperCase();
        }

        this.subjectCni?.next(cniData);
        this._deviceManagerStatut.next(true);
      });
    }
    return this.subjectCni.asObservable();
  }

  public fakeCni() {
    let cniData: ICniDto = {
      firstName: "Hichem David Michel",
      surName: "TAOUFIK",
      birthDay: "1980-09-28",
      sex: "M",
      cniId: "56879635",
      endValidity: "2028-09-01",
      //endValidity: null,
    }

    this.subjectCni?.next(cniData);
  }


  onQrCodeRead(): Observable<ClientQrCodeData> {
    if (!this.subjectQrCode) {
      this.subjectQrCode = new Subject();
      this.evtSource.addEventListener("qrcode", (event: any) => {
        console.log('deviceManager qrcode: ', <string>event.data);
        let data = ClientQrCodeData.Parse(<string>event.data);
        if (data) {
          this.subjectQrCode?.next(data);
        } else {
          this.errorService.errorAndDisplayMessage(this.translateService.instant("ERROR.QR_CODE_INVALID"));
        }

        this._deviceManagerStatut.next(true);
      });
    }
    return this.subjectQrCode.asObservable();
  }

  getSetting(): Observable<Settings> {
    if (this.isConnected) {
      return this.http.get<Settings>(`${this.baseUrl}/settings`).pipe(map((settings) => {

        this.saveCasinoCodeInSessionStorage(settings.currentCasinoCode);
        return settings;
      }));
    } else {
      let settings = LocalStorageService.get(LocalStorageService.SETTINGS) as Settings || new Settings();
      return of(settings);
    }
  }


  private saveCasinoCodeInSessionStorage(casinoCode: string | null) {
    let settings: Settings = LocalStorageService.get(LocalStorageService.SETTINGS);
    if (!settings) {
      settings = new Settings();
    }
    settings.currentCasinoCode = casinoCode;
    LocalStorageService.set(LocalStorageService.SETTINGS, settings);
  }

  setCurrentCasinoCode(casinoCode: string) {

    this.saveCasinoCodeInSessionStorage(casinoCode);

    if (this.isConnected) {
      this.http.put(`${this.baseUrl}/settings/casino`, {casinoCode}).pipe((res) => {
        res.subscribe({
          next: () => {
            console.log("New casinoCode value sent to devicemanager:", casinoCode)
          }
        });
        return res;
      });
    }
  }

  setTicketPrinter(ticketPrinter: string) {
    if (this.isConnected) {
      this.http.put(`${this.baseUrl}/settings/ticket-printer`, {ticketPrinter}).pipe((res) => {
        res.subscribe({
          next: () => {
            console.log("New ticketPrinter value sent to devicemanager:", ticketPrinter)
          }
        });
        return res;
      });
    }
  }
}
