import { Component, Input, OnInit, OnDestroy, Optional, AfterViewInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ActivatedRoute, ActivationStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { OfflineService } from './services/czam-api/offline.service'
import { OfflineDialogComponent } from './components/offline-dialog/offline-dialog.component';
import { BehaviorSubject, filter, map, Observable, Subscription } from 'rxjs';
import { SystemService } from './services/czam-api/system.service';
import { ICzamSystemInfoDto } from './services/czam-api/interfaces/czam-system-info-dto';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { AuthenticationService } from './services/authentication.service';
import { CasinoService } from './services/casino.service';
import { ICasinoDto } from './dtos/casino-dto';
import { LogoutDialogComponent } from './components/logout/logout-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GtmService } from './services/gtm.service';
import * as Sentry from "@sentry/angular";
import { BackOnlineDialogComponent } from "./components/back-online-dialog/back-online-dialog.component";
import { UtilsService } from "./services/utils.service";

@Component({
  selector: 'lib-czam-common',
  templateUrl: './czam-common.component.html',
  styleUrls: ['./czam-common.component.scss']
})
export class CzamCommonComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() title = '';
  @Input() appName = '';
  @Input() withBackgroundLogo = false;
  @Input() setHeaderBackGround: boolean = true;
  @Input() isOffline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private offlineModalRef: MatDialogRef<OfflineDialogComponent> | null = null;
  private subscriptions: Subscription[] = [];
  public backEndSystem: ICzamSystemInfoDto = {} as any;
  public currentTheme: string = "basic";
  public isLoggedIn: Observable<boolean>;
  public username: Observable<string>;
  public casino?: Observable<ICasinoDto | null>;
  public isHeaderDisplayed = false;
  private pageSpan: Sentry.Span | null = null;
  private _isOfflinePage: boolean | null = null; // Boolean telling if a page is an offline page
  private _isActivated: boolean | null = null; // Boolean telling that the current route is activated
  private _isOffline: boolean | null = null; // Boolean telling if the network is offline


  constructor(
    public router: Router,
    public dialog: MatDialog,
    public offlineService: OfflineService,
    private systemService: SystemService,
    private translator: TranslateService,
    private snackbar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private gtmService: GtmService,
    @Optional() public casinoService: CasinoService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService
  ) {
    this.isLoggedIn = this.authenticationService.isLoggedIn.asObservable();
    this.username = this.authenticationService.getUsername();

    if (this.casinoService) {
      this.casino = this.casinoService.getCurrentCasino();
    }

    this.matIconRegistry.addSvgIcon(
      'rfid-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/lib-common/icons/rfid-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'b-play-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/lib-common/icons/b-play-logo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ultimate-card-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/lib-common/images/card-ultimate.svg')
    );
  }

  ngOnInit(): void {

    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart) {
        // this.checkHeaderDisplayed();
        this._isActivated = true;
        if (data.snapshot.data['offline']) {
          this.displayOfflineLayout();
          this._isOfflinePage = data.snapshot.data['offline'];
        } else {
          this.hideOfflineLayout();
          this._isOfflinePage = false;
        }

        if (!this._isOfflinePage && this._isOffline) {
          this.openOfflineDialog();
        }

      }
    });

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.onPageLoadEnd(event);
    });

    // show offline modal dialog when CZAM goes offline
    let sub = this.offlineService.$isOffline.subscribe({
      next: async (isOffline: boolean) => {
        this._isOffline = isOffline;
        if (!this._isActivated) {
          return;
        }
        // CZAM is offline
        if (isOffline) {

          if (this._isOffline && (this.offlineModalRef != null && this.offlineModalRef.getState() == MatDialogState.OPEN)) {
            return;
          }

          // Skip online dialog when offline page
          if (this._isOfflinePage) {
            return;
          }

          this.openOfflineDialog();
        }
        // CZAM is online
        else {
          // only close if not already closed
          if (this.offlineModalRef != null && this.offlineModalRef.getState() == MatDialogState.OPEN) {
            this.closeOfflineDialog();
          } else if (!isOffline && this._isOfflinePage) {
            this.backOnlineDialog();
          }
        }
      }
    });
    this.subscriptions.push(sub);

    sub = this.systemService.getParameters().subscribe(result => {
      this.backEndSystem = result;
    })
    this.subscriptions.push(sub);
    // show message to user when CZAM is back online
    sub = this.offlineService.$isConnectionRestored.subscribe({
      next: (isRestored: boolean) => {
        // CZAM is back online !
        if (isRestored) {
          this.showConnectionRestored();
        }
      }
    });
    this.subscriptions.push(sub);
    // filter navigation events, update them according to route
    sub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let theme = 'basic';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['theme']) {
            theme = route!.snapshot.data['theme'];
          }
          return theme;
        })
      )
      .subscribe((theme: string) => {
        this.currentTheme = theme;
      });
    this.subscriptions.push(sub);

    sub = this.isOffline$.subscribe(isOffline => {
      if (isOffline && this._isOfflinePage) {
        this.displayOfflineLayout();
      } else {
        this.hideOfflineLayout();
      }
    })

    this.subscriptions.push(sub);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.onPageLoadStarted(event);
      }
    });

  }


  // opens a modal that tell user that CZAM is in offline-mode (no internet or no connection to CZAM backend)
  private openOfflineDialog(): void {
    this.offlineModalRef = this.dialog.open(OfflineDialogComponent, {
      width: this.utilsService.isMobile() ? '73vw' : '32vw',
      height: '45vh',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
      data: this.utilsService.isMobile(),
      disableClose: true
    })
  }

  private backOnlineDialog(): void {
    this.dialog.open(BackOnlineDialogComponent, {
      width: '32vw',
      height: '45vh',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
      disableClose: true // forbid user to close the dialog by clicking anywhere on screen
    });
  }

  private closeOfflineDialog(): void {
    this.offlineModalRef?.close();
    this.offlineModalRef = null;
    this.showConnectionRestored();
  }

  // show message to user when connection is restored
  private showConnectionRestored(): void {
    this.translator.get('SNACKBAR.LIVE_ON').subscribe(message => {
      this.snackbar.openFromComponent(
        SnackBarComponent, {
          data: {message: message, icon: 'wifi', class: 'success'},
          duration: 3000,
          panelClass: 'dark-snackbar-success'
        });
    });
  }

  // Open dialog to change casino
  public openSelectionDialog() {
    if (this.casinoService) {
      this.casinoService.openSelectCasinoDialog().then();
    }
  }

  public openLogoutDialog(): void {
    this.dialog.open(LogoutDialogComponent, {
      width: '500px',
      height: '300px',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
    })
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {

    this.gtmService.appName = this.appName;

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(async (event: any) => {
      let e = event as NavigationEnd;
      await this.checkHeaderDisplayed(e.url)
      if (!this._isOffline) {
        await this.gtmService.sendPageViewTag(e.urlAfterRedirects);
      }
    });
  }

  onPageLoadStarted(event: NavigationStart) {

    let data = this.activatedRoute.snapshot.data;

    if (data['offline']) {
      console.log('DATA OFFLINE', data['offline'])
    }

    Sentry.startSpanManual({name: event.url, op: 'ui.angular.routing'}, (span) => {
        this.pageSpan = span;

        console.log(`Page Load: ${event.url}, current span: ${JSON.stringify(span)}`);

        let rootSpan = Sentry.getRootSpan(span);
        console.log(`Page Load: ${event.url}, root span: ${JSON.stringify(rootSpan)}`);
      }
    );
  }

  onPageLoadEnd(event: NavigationEnd) {
    // Check if the URL contains 'version' in any segment
    if (event.url.includes('version')) {
      this.setHeaderBackGround = false; // Set your desired color
    }

    if (this.pageSpan != null) {
      this.pageSpan.end();
      this.pageSpan = null;
    }
  }

  displayOfflineLayout() {
    this.renderer.addClass(document.body, 'offline-border');
    this.renderer.addClass(document.getElementById('offline-container'), 'offline-container');
    this.renderer.removeClass(document.getElementById('offline-container'), 'offline-hidden');
  }

  hideOfflineLayout() {
    const offlineContainer = document.getElementById('offline-container');
    if (offlineContainer) {
      this.renderer.removeClass(document.body, 'offline-border');
      this.renderer.removeClass(document.getElementById('offline-container'), 'offline-container');
      this.renderer.addClass(document.getElementById('offline-container'), 'offline-hidden');
    }
  }

  async checkHeaderDisplayed(url: string) {
    const isLoginPage = url.includes('/login');
    this.isHeaderDisplayed = !isLoginPage;
  }


}
