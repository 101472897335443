<div id="homePage" class="homePage">
  <div id="cardsAndTitle" class="cardsAndTitle">
    <div id="title">
      <span class="title">{{ 'MENU.HOMEPAGE.COMPANY_NAME' | translate }}</span>
    </div>
    <div id="cards" class="cards">
      <ng-container>
        <div class="card-container no-display-on-mobile" [matTooltip]="tooltip | translate">
          <a id="check-in-card" [ngClass]="{'icon-gray': !hasDeviceManager}" href="/czam/check-in-offline">
            <div id="vdi-card" class="card" [ngClass]="{'disabled-card': !hasDeviceManager}">
              <div id="vdi-logo">
                <img src="assets/icons/vdi.svg">
              </div>
              <div class="cardText">
                <div class="itemTitle" [ngClass]="{'itemTitle-disabled': !hasDeviceManager}">
                  <span>{{ 'MENU.HOMEPAGE.VDI' | translate }} </span>
                </div>
                <div class="itemLabel">
                  <span>{{ 'MENU.HOMEPAGE.CASINO' | translate }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </div>
    <div [innerHTML]="'MENU.HOMEPAGE.OFFLINE_INFO' | translate" id="offline-info" class="offline-info"></div>
  </div>
</div>
