import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceManagerService } from "lib-common";

@Component({
  selector: 'app-home-page-offline',
  templateUrl: './home-page-offline.component.html',
  styleUrls: ['./home-page-offline.component.scss']
})
export class HomePageOfflineComponent implements OnInit, OnDestroy {

  hasDeviceManager = false;
  tooltip = '';

  constructor(private deviceManager: DeviceManagerService) {
    this.deviceManager.$isAlive.subscribe(isAlive => {
      this.hasDeviceManager = isAlive;
      this.tooltip = this.hasDeviceManager ? '' : 'MENU.OFFLINE_HOME_PAGE.TOOLTIP.NO_DEVICE_MANAGER';
    })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

}
