import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from "../../services/utils.service";

/***
 * This component is used to show a modal dialog on top of the App
 * when the frontend loses connection and switches to offline-mode
 */
@Component({
  selector: 'app-offline-modal',
  templateUrl: './offline-dialog.component.html',
  styleUrls: ['./offline-dialog.component.scss']
})
export class OfflineDialogComponent {

  public constructor(private modalDialogRef: MatDialogRef<OfflineDialogComponent>, @Inject(MAT_DIALOG_DATA) public isMobile: boolean) {
  }

  public onManualClose(): void {
    window.location.href = "/home/dashboard-offline";
    this.modalDialogRef.close();
  }
}
