import { Injectable, Optional } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, fromEvent, Observer, Observable, merge, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { CasinoService } from '../casino.service';
import { IOfflineModeDto } from "./dtos/downgraded-mode-dto";
import { ErrorService } from '../error.service';

// This class is used to handle offline-mode when CZAM API backend couldn't be reached
// It stores the current status (offline or normal) and send information to backend when connection is restored
@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  // backend url to push infos when connectio is restored
  private apiUrl: string = environment.czamApiUrl + '/downgraded';
  private backendPingFrequencyinSecs: number = 15000;

  // observable on offline-mode status
  public $isOffline: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public $isConnectionRestored: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private offlineTimestamp: Date | null = null;

  public constructor(@Optional() private casinoService: CasinoService, private http: HttpClient, private errorService: ErrorService) {
    // set offline-mode first value to false
    this.setOfflineMode(false);

    // observable on 'navigator.onLine' property
    this.updatedBrowserOnlinePropertyAsync();

    // ping backend to know when backend is (un)reachable
    this.pingBackend();

    this.$isOffline.subscribe(val => {
      this.errorService.Active(val == false);
    })
  }

  // This method is used to toggle between offline-mode and normal-mode
  // It's called from TimeoutRequestInterceptor when an HTTP request failed to reach CZAM backend
  public setOfflineMode(status: boolean): void {
    // update offline-mode status
    this.$isOffline.next(status);

    // continue only on status change
    const previousState = this.$isOffline.value;
    if (status == previousState) {
      return;
    }
    // we are in offline-mode
    if (status) {
      this.$isConnectionRestored.next(false);

      this.offlineTimestamp = this.getUtcDate();
      console.log(`CZAM has switched to offline-mode at ${this.offlineTimestamp.toISOString()}`);
    }
    // we are back to normal
    else {
      // TODO: catch errors on postofflineInfo(); if error occured, don't set isConnectionRestored to true not timestamp to null

      // send offline info to backend when connection is restored
      if (this.offlineTimestamp != null) {
        console.log(`CZAM has restored its connection at ${this.offlineTimestamp.toISOString()}`);
        this.postOfflineInfo();
      }
      // TODO: if (success) {}
      this.$isConnectionRestored.next(true);
      this.offlineTimestamp = null;
    }
  }

  // Send a ping request to backend every 10 seconds, when connection is lost
  private pingBackend(): void {
    interval(this.backendPingFrequencyinSecs).subscribe({
      next: (triggered) => {
        // ping only when connection is down
        if (this.$isOffline.value == true) {
          this.http.get(`${this.apiUrl}/is-alive`).subscribe({
            next: () => {
              // backend is reachable, switch to normal mode
              this.setOfflineMode(false);
            },
            error: () => {
              // we can't ping the backend, switch to offline-mode
              this.setOfflineMode(true);
            }
          });
        }
      }
    });
  }

  // Send offline info to backend, for logging purpose
  private postOfflineInfo(): void {


    let casinoCode = null;
    if (this.casinoService) {
      casinoCode = this.casinoService.getCasinoCodeSync();
    }

    const info: IOfflineModeDto = { startDate: this.offlineTimestamp!, endDate: this.getUtcDate(), casino: casinoCode };

    this.http.post(`${this.apiUrl}/restored`, info).subscribe({
      error: () => {
        // we can't http.post() to backend, switch back to offline-mode :(
        this.setOfflineMode(true);
      }
    });
  }

  // Subscribe to changes of browser 'online' property and switch to offline-mode when losing connection
  private updatedBrowserOnlinePropertyAsync(): void {
    // subscribe on changes of 'navigator.onLine' property
    let observer = merge(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));

    observer.subscribe({
      next: (status: boolean) => {
        this.setOfflineMode(!status);
      }
    });
  }

  // Get current date as UTC
  private getUtcDate(): Date {
    const now = new Date();
    return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
  }
}
