import { Component } from '@angular/core';
import { AppUpdateService } from 'lib-common';
import { OfflineService } from "lib-common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private checkUpdate: AppUpdateService, public offlineService: OfflineService) {
    this.checkUpdate.checkUpdate('HOME-PAGE');
  }
}
