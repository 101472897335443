// WARNING, do not touch the double quote (") chars used in version, commitHash, and installationDate, it used by the CI/CD
export const envBase = {
  "czamApiUrl": "/czam-api",
  "restrictionsApiUrl": "/casino-restrictions-api",
  "casinoInjApiUrl": "/casino-inj-api",
  "swiftSaleApiUrl": "/swift-sale-api",
  "appsApiUrl": "/user-apps-api",
  "ocmConnectApiUrl": "/ocm-connect-api",
  "customerExtendedApiUrl": "/api-fiche-client",
  "casinoLoyaltyApiUrl": "/casino-loyalty-api",
  "walletApiUrl": "/wallet-api",
  "notificationCenterApiUrl": "/notifications-api",
  "hotelUnifiedDataApiUrl": "/hotel-unifieddata-api",
  "datascienceApiUrl": "/datascience-api",
  "version": "1.19.1.3875",
  "commitHash": "f866cc6d6788c8f10924b6bb4b8f08cb7911aaae",
  "packageGenerationDate": "2025-01-23T09:46:24Z",
  "asyncClientEntry": false,
  "numberOfSearchChar": 4,
  daysBeforeSignalCardExpireSoon: 15
};
